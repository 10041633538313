<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS SERVICIOS:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-cube fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaServicios.length}}</span>
                            <br>
                            <span class="text-muted">Servicios registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de servicios</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col  v-if="checkPermissions('008-PRO-SER','c') == 1"  cols="4" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarServicio = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo servicio</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Servicios registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaServicios" :fields="camposProyecto" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('008-PRO-SER','u') == 1"  size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalActualizarServicio(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                    <b-button  v-if="checkPermissions('008-PRO-SER','d') == 1" size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarServicio(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarServicio" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo servicio </span>
            </h6>
            <CButtonClose @click="modalRegistrarServicio = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarServicio)">
                <b-row>
                    <b-col lg="3">
                        <validation-provider name="número de servicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de servicio:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número" v-model="datosServicio.numeroServicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="5">
                        <validation-provider name="servicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Servicio:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el servicio" v-model="datosServicio.servicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <!-- <validation-provider name="cliente" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cliente:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cliente" v-model="datosServicio.cliente"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->
                        <validation-provider name="cliente" rules="required" v-slot="{errors}">
                            <b-form-group label="Cliente:" class="mb-2">
                                <v-select :reduce="comboClientes =>comboClientes.idClienteProyecto" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosServicio.idClienteProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosServicio.idClienteProyecto" :options="comboClientes" @search:blur="blurCliente">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Lista de Clientes'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12">
                        <validation-provider name="descripción" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosServicio.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarServicio = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarServicio" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar servicio</span></h6>
            <CButtonClose @click="modalActualizarServicio = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarServicio)">
                <b-row>
                    <b-col lg="3">
                        <validation-provider name="número de servicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de servicio:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el número" v-model="datosActualizarServicio.numeroServicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="5">
                        <validation-provider name="servicio" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Servicio:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el servicio" v-model="datosActualizarServicio.servicio"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <!-- <validation-provider name="cliente" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Cliente:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el cliente" v-model="datosActualizarServicio.cliente"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->
                        <validation-provider name="cliente" rules="required" v-slot="{errors}">
                            <b-form-group label="Cliente:" class="mb-2">
                                <v-select :reduce="comboClientes =>comboClientes.idClienteProyecto" label="razonSocial" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarServicio.idClienteProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarServicio.idClienteProyecto" :options="comboClientes" @search:blur="blurActualizarCliente">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Lista de Clientes'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12">
                        <validation-provider name="descripción" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarServicio.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarServicio = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            disabled: false,
            listaServicios: [],
            camposProyecto: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "numeroServicio",
                    label: "Número del servicio",
                    class: "text-center",
                },
                {
                    key: "servicio",
                    label: "Servicio",
                    class: "text-center",
                },
                {
                    key: "cliente",
                    label: "Cliente",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],
            comboClientes: [],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosServicio: {
                idCliente: '',
                numeroServicio: '',
                servicio: '',
                idClienteProyecto: '',
                descripcion: '',
            },
            datosActualizarServicio: {
                idServicio: '',
                idCliente: '',
                numeroServicio: '',
                servicio: '',
                idClienteProyecto: '',
                descripcion: '',
            },
            modalRegistrarServicio: false,
            modalActualizarServicio: false,
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        listarClientes() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-clientes-proyectos", {
                        params: {
                            idCliente: me.datosServicio.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboClientes = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarServicios() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-servicios-proyectos", {
                        params: {
                            idCliente: me.datosServicio.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaServicios = response.data;
                    me.filasTotales = me.listaServicios.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarServicio() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-servicio-proyecto",
                    me.datosServicio, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarServicio = false;
                    me.listarServicios();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        actualizarServicio() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-servicio-proyecto",
                    me.datosActualizarServicio, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarServicio = false;
                    me.listarServicios();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        eliminarServicio(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el servicio?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-servicio-proyecto", {
                                idServicio: param.item.idServicio
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarServicios();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        abrirModalActualizarServicio(param) {
            this.datosActualizarServicio.idServicio = param.item.idServicio;
            this.datosActualizarServicio.numeroServicio = param.item.numeroServicio;
            this.datosActualizarServicio.servicio = param.item.servicio;
            this.datosActualizarServicio.idClienteProyecto = param.item.idClienteProyecto;
            this.datosActualizarServicio.descripcion = param.item.descripcion;
            this.modalActualizarServicio = true
        },
        blurCliente() {
            this.computedForm.refs.cliente.validate();
        },
        blurActualizarCliente() {
            this.computedActualizarForm.refs.cliente.validate();
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetModalRegistrarServicio() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosServicio.numeroServicio = '';
            this.datosServicio.servicio = '';
            this.datosServicio.idClienteProyecto = null;
            this.datosServicio.descripcion = '';
        },
        resetModalActualizarServicio() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalRegistrarServicio: function (val) {
            if (val == false) {
                this.resetModalRegistrarServicio();
            }
        },
        modalActualizarServicio: function (val) {
            if (val == false) {
                this.resetModalActualizarServicio();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosServicio.idCliente = this.datosActualizarServicio.idCliente = user.uidClient;
            this.listarClientes();
            this.listarServicios();
        }
    }

}
</script>
